











































































































































import { Component, Mixins, Watch } from 'vue-property-decorator'
import Chat16 from '@carbon/icons-vue/es/chat/16'
import Account16 from '@carbon/icons-vue/es/account/16'
import View16 from '@carbon/icons-vue/es/view/16'
import { namespace } from 'vuex-class'
import axios from 'axios'
import camelCaseKeys from 'camelcase-keys'
import snakeCaseKeys from 'snakecase-keys'

import TablePagination from '@/partials/tables/Pagination.vue'
import PermissionsMixin from '@/mixins/permissionsMixin'
import RegionalsSideNav from '@/partials/components/RegionalsSideNav.vue'
import ScoreTag from '@/partials/components/Service/SatisfactionScoreTag.vue'

const sessionModule = namespace('session')

@Component({
  components: {
    TablePagination,
    RegionalsSideNav,
    Chat16,
    Account16,
    View16,
    ScoreTag
  }
})
export default class ServicesIndex extends Mixins(PermissionsMixin) {
  @sessionModule.State(state => state) sessionState!: Record<string, any>
  @sessionModule.Getter isCommonUser!: boolean
  selectedRegional: Array<string> = []
  services: any[] = []
  servicesTotal = 0
  isHomelessSpecialist = false

  statusTabs = [
    {
      label: 'Ativos',
      query: undefined
    },
    {
      label: 'Vistoria',
      query: 'visit'
    },
    {
      label: 'Moratória',
      query: 'moratorium'
    },
    {
      label: 'Inativos',
      query: 'inactive'
    },
    {
      label: 'Demonstração',
      query: 'demo'
    },
    {
      label: 'Independentes',
      query: 'independent'
    },
    {
      label: 'Aspirantes',
      query: 'aspiring'
    }
  ]

  selectedTab = {}

  created () {
    // this.filterServices()

    if (this.$route.query.regional) {
      this.selectedRegional = Array.isArray(this.$route.query.regional) ? this.$route.query.regional as Array<string> : [this.$route.query.regional]
    }
  }

  onPageChange (pagination: Pagination) {
    this.fetchServices(pagination.page, pagination.length)
  }

  @Watch('$route.query.status')
  @Watch('$route.query.regional')
  onRouteChange () {
    this.fetchServices()
  }

  fetchServices (page = 1, perPage = 20) {
    let route = 'services'
    const { status, regional } = this.$route.query

    if (regional) {
      route += regional.length ? `?regional=${regional}` : ''
    }
    const perPageSnake = snakeCaseKeys({ perPage })
    axios.get(route, {
      params: {
        'page[number]': page,
        ...perPageSnake,
        status: this.hasPermission(this.permissions.SERVICETAB.READ) ? status : 'demo,active,visit,independent,aspiring'
      }
    })
      .then((response) => {
        this.servicesTotal = response.data.total
        this.services = camelCaseKeys(response.data.data, { deep: true })

        if (!this.hasPermission(this.permissions.SERVICETAB.READ)) {
          this.isHomelessSpecialist = this.services.some(service => ['independent', 'aspiring'].includes(service.status))

          this.services = this.services.filter((service) => {
            const isAspiringOrIndependent = ['independent', 'aspiring'].includes(service.status)

            return (isAspiringOrIndependent && service.hasScheduledExams) ||
                    !isAspiringOrIndependent
          })
        }
      })
  }

  @Watch('selectedRegional')
  regionalChange () {
    const newRoute = {
      query: {
        status: this.$route.query.status,
        regional: this.selectedRegional
      }
    }

    this.switchRoute(newRoute)
  }

  get isChiefWithoutService () {
    return this.sessionState.user.profile?.some((profile: any) => profile.metaKey === 'isChiefWithoutService')
  }

  tabChange (tab: number) {
    const route = this.statusTabs[tab]
    this.selectedTab = route

    const newRoute: Record<string, any> = {
      query: {
        status: route.query,
        regional: this.selectedRegional
      }
    }

    if (newRoute.query.regional.length === 0) {
      delete (newRoute.query.regional)
    }

    this.switchRoute(newRoute)
  }

  switchRoute (newRoute: Record<string, any>) {
    if (this.$route.name === 'Services' && this.$router.resolve(newRoute).href !== this.$route.fullPath) {
      this.$router.push(newRoute)
    }
  }
}
