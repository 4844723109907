









import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class SatisfactionScoreTag extends Vue {
  @Prop({ default: '' }) satisfaction!: number

  get tagClass () {
    let color = ''
    if (this.satisfaction < 2.5) {
      color = 'bg-ibm-red-30'
    }

    if (this.satisfaction >= 2.5) {
      color = 'bg-ibm-yellow-30'
    }

    if (this.satisfaction >= 3.5) {
      color = 'bg-ibm-green-30'
    }

    if (this.satisfaction >= 4.5) {
      color = 'bg-ibm-blue-30'
    }

    return `${color} text-gray-800 m-4`
  }
}
